import React from "react"
import { motion } from "framer-motion"

import hand from "./handcursor.png"
import "./styles.scss"

const SwipeAlert = ({ toggleLightBox }) => {
  const swipeAlert = {
    in: {
      y: [100, -5, -5, 100],
      opacity: [0, 1, 1, 0],
      visibility: "visible",
      transition: {
        type: "spring",
        bounce: 0.05,
        duration: 5,
        times: [0, 0.1, 0.9, 1],
        delay: 0.6,
      },
    },
    out: {
      y: 100,
      visibility: "hiddent",
      opacity: [1, 0],
      transition: {
        type: "spring",
        bounce: 0.05,
        duration: 0.3,
      },
    },
  }

  return (
    <motion.div
      initial="out"
      variants={swipeAlert}
      animate={toggleLightBox ? "in" : "out"}
      className="mobile-swipe-lightbox"
    >
      <div className="mobile-swipe-lightbox-box">
        <span>← </span>
        <img src={hand} alt="" />
        <span> →</span>
      </div>
    </motion.div>
  )
}

export default SwipeAlert
