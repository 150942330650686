import React from "react"
import { motion } from "framer-motion"

import "./styles.scss"

const CloseBtn = ({ langSliced, handleCloseLightBox }) => {
  const closeButton = {
    in: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.05,
        duration: 0.5,
        delay: 0.8,
      },
    },
    out: {
      y: -20,
      opacity: 0,
      transition: {
        type: "spring",
        bounce: 0.05,
        duration: 0.5,
      },
    },
  }

  return (
    <motion.div
      initial="out"
      className="lightbox-close"
      onClick={handleCloseLightBox}
      variants={closeButton}
    >
      <p className="lightbox-close-cross">X</p>
      <div className="lightbox-close-text">
        <p className="lightbox-close-text-normal">
          {langSliced === "en"
            ? "close"
            : langSliced === "fr"
            ? "fermer"
            : "закрыть"}
        </p>
        <p className="lightbox-close-text-hover">
          {langSliced === "en"
            ? "close"
            : langSliced === "fr"
            ? "fermer"
            : "закрыть"}
        </p>
      </div>
    </motion.div>
  )
}

export default CloseBtn
