import React from "react"
import { motion } from "framer-motion"

import "./styles.scss"

const BackButton = ({ langSliced, toggleLightBox, handleTransition }) => {
  const backButton = {
    out: {
      y: 20,
      opacity: 0,
      transition: {
        type: "spring",
        bounce: 0.05,
        duration: 0.5,
        delay: 0.4,
      },
    },
    in: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.05,
        duration: 0.5,
        delay: 0.9,
      },
    },
  }

  return (
    <motion.div
      className="paintingsGallery-back"
      onClick={() => {
        handleTransition("/paintings")
      }}
      variants={backButton}
      animate={toggleLightBox ? "out" : "in"}
    >
      <p className="paintingsGallery-back-cross">←</p>
      <div className="paintingsGallery-back-text">
        <p className="paintingsGallery-back-text-normal">
          {langSliced === "en"
            ? "back"
            : langSliced === "fr"
            ? "retour"
            : "назад"}
        </p>
        <p className="paintingsGallery-back-text-hover">
          {langSliced === "en"
            ? "back"
            : langSliced === "fr"
            ? "retour"
            : "назад"}
        </p>
      </div>
    </motion.div>
  )
}

export default BackButton
