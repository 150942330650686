import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import { ParallaxProvider } from "react-scroll-parallax"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"
import socialImage from "../../static/social-share/DMITRIEVA-ART-social-share.jpg"
import SEO from "../components/seo"
import PaintingsGallery from "../components/PaintingsGallery"

const PaintingsGalleryPage = ({ data, location }) => {
  const content = data.prismicPaintgallery.data
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)
  const year = content.gallery_year
  const toggleTransition = state.toggleTransition
  const path = location.pathname
  const langSliced = path.slice(1, 3)
  useEffect(() => {
    if (toggleTransition) {
      setTimeout(() => {
        dispatch({ type: "TOGGLE_TRANSITION" })
      }, 600)
    }
  }, [])
  return (
    <>
      <ParallaxProvider>
        <SEO
          title={
            langSliced === "fr"
              ? "Peintures " + year
              : langSliced === "ru"
              ? "Живопись " + year
              : "Paintings " + year
          }
          image={socialImage}
          lang={langSliced}
          path={path}
        />
        <PaintingsGallery location={location} content={content} />
      </ParallaxProvider>
    </>
  )
}

export default PaintingsGalleryPage

export const query = graphql`
  query PaintingsGallery($lang: String!, $id: ID!) {
    prismicPaintgallery(lang: { eq: $lang }, prismicId: { eq: $id }) {
      data {
        gallery_year
        painting_artworks {
          painting_dimensions
          painting_image {
            url(imgixParams: { w: 1000, q: 10 })
          }
        }
      }
    }
  }
`
