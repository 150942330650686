import React from "react"
import { motion } from "framer-motion"

import "./styles.scss"

const NextBtn = ({ handleNextSlide }) => {
  const nextButton = {
    in: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.05,
        duration: 0.3,
        delay: 0.8,
      },
    },
    out: {
      x: -20,
      opacity: 0,
      transition: {
        type: "spring",
        bounce: 0.05,
        duration: 0.3,
      },
    },
  }

  return (
    <motion.div
      initial="out"
      variants={nextButton}
      onTap={handleNextSlide}
      whileHover={{
        x: 5,
        transition: {
          type: "spring",
          bounce: 0.05,
          duration: 0.3,
        },
      }}
      className="lightbox-btn-next"
    >
      →
    </motion.div>
  )
}

export default NextBtn
