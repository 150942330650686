import React from "react"
import { motion } from "framer-motion"
import { Parallax } from "react-scroll-parallax"

import "./styles.scss"

const Year = ({ content, toggleLightBox }) => {
  const year1 = {
    out: {
      y: -150,
      opacity: 0,
      transition: {
        type: "spring",
        bounce: 0.05,
        duration: 0.8,
      },
    },
    in: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.05,
        duration: 0.8,
        delay: 0.4,
      },
    },
  }
  const year2 = {
    out: {
      y: 70,
      scale: 0.95,
      opacity: 0,
      transition: {
        type: "spring",
        bounce: 0.05,
        duration: 0.8,
      },
    },
    in: {
      y: 0,
      scale: 1,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.05,
        duration: 0.8,
        delay: 0.4,
      },
    },
  }

  return (
    <div className="paintingsGallery-year">
      <Parallax y={[60, 10]}>
        <motion.div
          initial="out"
          variants={year1}
          animate={toggleLightBox ? "out" : "in"}
          className="paintingsGallery-year-first"
        >
          {content.gallery_year.slice(0, 2)}
        </motion.div>
      </Parallax>
      <Parallax y={[-30, 30]}>
        <motion.div
          initial="out"
          variants={year2}
          animate={toggleLightBox ? "out" : "in"}
          className="paintingsGallery-year-last"
        >
          {content.gallery_year.slice(2, 4)}
        </motion.div>
      </Parallax>
    </div>
  )
}

export default Year
