import React from "react"
import { motion } from "framer-motion"

import Image from "./Image"
import NextBtn from "./NextBtn"
import PrevBtn from "./PrevBtn"
import CloseBtn from "./CloseBtn"
import SwipeAlert from "./SwipeAlert"
import "./styles.scss"

const LightBox = ({
  content,
  slides,
  current,
  langSliced,
  toggleLightBox,
  slideTransition,
  handlers,
  handleCloseLightBox,
  handleNextSlide,
  handlePrevSlide,
}) => {
  const lightBoxContainer = {
    in: {
      opacity: 1,
      visibility: "visible",
      transition: {
        type: "spring",
        bounce: 0.05,
        duration: 0.1,
        // delay: 0.4,
      },
    },
    out: {
      opacity: 1,
      visibility: "hidden",
      transition: {
        type: "spring",
        bounce: 0.05,
        duration: 0.8,
        delay: 0.8,
      },
    },
  }

  const lightBoxOverlay = {
    in: {
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.05,
        duration: 0.8,
        delay: 0.4,
      },
    },
    out: {
      opacity: 0,
      transition: {
        type: "spring",
        bounce: 0.05,
        duration: 0.5,
        delay: 0.1,
      },
    },
  }

  return (
    <motion.div
      variants={lightBoxContainer}
      animate={toggleLightBox ? "in" : "out"}
      className="lightbox"
    >
      <div className="lightbox-swipe" {...handlers}>
        <SwipeAlert toggleLightBox={toggleLightBox} />
        <motion.div variants={lightBoxOverlay} className="lightbox-overlay" />
        <CloseBtn
          langSliced={langSliced}
          handleCloseLightBox={handleCloseLightBox}
        />
        <Image
          content={content}
          slides={slides}
          current={current}
          slideTransition={slideTransition}
          handleCloseLightBox={handleCloseLightBox}
        />
        <NextBtn handleNextSlide={handleNextSlide} />
        <PrevBtn handlePrevSlide={handlePrevSlide} />
      </div>
    </motion.div>
  )
}

export default LightBox
