import React from "react"
import Img from "gatsby-image"
import { motion } from "framer-motion"

import "./styles.scss"

const Image = ({
  content,
  slides,
  current,
  slideTransition,
  handleCloseLightBox,
}) => {
  const imageCurrent = {
    in: {
      scale: 1,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.05,
        duration: 0.8,
        delay: 0.4,
      },
    },
    out: {
      scale: 0.7,
      opacity: 0,
      transition: {
        type: "spring",
        bounce: 0.05,
        duration: 0.8,
      },
    },
  }
  const imageTransition = {
    transitionIn: {
      scale: [1.1, 1],
      opacity: [0, 1],
      transition: {
        type: "spring",
        bounce: 0.05,
        duration: 0.3,
        delay: 0.1,
      },
    },
    transitionOut: {
      scale: [1, 0.9],
      opacity: [1, 0],
      transition: {
        type: "spring",
        bounce: 0.05,
        duration: 0.3,
      },
    },
  }
  const infosTransition = {
    transitionIn: {
      y: [-5, 0],
      opacity: [0, 1],
      transition: {
        type: "spring",
        bounce: 0.05,
        duration: 0.3,
        delay: 0.2,
      },
    },
    transitionOut: {
      y: [0, 10],
      opacity: [1, 0],
      transition: {
        type: "spring",
        bounce: 0.05,
        duration: 0.3,
      },
    },
  }

  return (
    <motion.div
      variants={imageCurrent}
      initial="out"
      className="lightbox-image"
    >
      <motion.img
        variants={imageTransition}
        initial="transitionOut"
        animate={slideTransition ? "transitionOut" : "transitionIn"}
        className="lightbox-image-picture"
        src={slides[current].painting_image.url}
        alt={`painting ${content.gallery_year} ${slides[current].painting_dimensions}`}
        onClick={handleCloseLightBox}
      />

      <motion.div
        variants={infosTransition}
        initial="transitionOut"
        animate={slideTransition ? "transitionOut" : "transitionIn"}
        className="lightbox-image-infos"
      >
        <p>{content.gallery_year}</p>
        <p>|</p>
        <p>{slides[current].painting_dimensions}</p>
      </motion.div>
    </motion.div>
  )
}

export default Image
